import React from 'react';

const MissingPageImage = () => {
  return (
    <div className=" lg:inline-block w-6/12 flex-shrink min-h-full content-center m-8 md:p-8 items-center">
      <div className="min-w-screen">
        <p className=" font-black text-9xl text-center text-gray-200">404</p>
        <p className=" font-bold text-center text-gray-500">Página no encontrada</p>
      </div>
    </div>
  );
};

export default MissingPageImage;