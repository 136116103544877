import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import MissingPageSection from '../sections/MissingPage/MissingPageSection';

const NotFoundPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'seo.404.title' })}
        description={intl.formatMessage({ id: 'seo.404.description' })}
      />
      <MissingPageSection />
    </Layout>
  );
};

export default NotFoundPage;
