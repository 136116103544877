import React from 'react';

const MissingPageText = () => {
  return (
    <div className="block lg:inline-block lg:w-4/12 m-w-screen flex-shrink align-top m-8 relative">
      <h1 className="font-black text-3xl md:text-6xl mb-8 flex-shrink">Página no encontrada<span className="text-blue-900">...</span></h1>
      <p className="font-black text-2xl">La página a la cual está tratando de acceder no existe.</p>
    </div >
  );
};

export default MissingPageText;