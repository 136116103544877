import React from 'react';
import MissingPageText from '../../components/MissingPage/MissingPageText';
import MissingPageImage from '../../components/MissingPage/MissingPageImage';

const MissingPageSection = () => {
  return (
    <section className="p-5">
      <main className="flex lg:flex-row flex-col-reverse min-h-full lg:h-96 ml-32">
        <MissingPageText />
        <MissingPageImage />
      </main>
    </section>
  );
};

export default MissingPageSection;